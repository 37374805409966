import { lazy, ComponentType} from 'react';
import { URLS } from './urls/urls';
const Starts = lazy(() => import('../features/starts/Starts'));
const Companies = lazy(() => import('../features/organizational-units/companies/Companies'));
const CompanyDetail = lazy(() => import('../features/organizational-units/company-detail/CompanyDetail'));
const DivisionDetail = lazy(() => import('../features/organizational-units/division-detail/DivisionDetail'));
const Projects = lazy(() => import('../features/projects/Projects'));
const StartTracker = lazy(() => import('../features/starts/tracker/StartTracker'));
const Dashboard = lazy(() => import('../features/dashboard/Dashboard'));
const ReportView = lazy(() => import('../features/reports/office-reports/OfficeReports'));
const AssuranceDetail = lazy(() => import('../features/assurance/assurance-detail/AssuranceDetail'));
const StartDetail = lazy(() => import('../features/starts/start-detail/StartDetail'));
const FieldReports = lazy(() => import('../features/reports/field-reports/FieldReports'));
const WrappedItemView = lazy(() => import('../features/projects/project-items/WrappedItemView'));
const WarehouseOrders = lazy(() => import('../features/warehouse/tracker/WarehouseOrders'));
const WarehouseOrderDetail = lazy(() => import('../features/warehouse/detail/WarehouseOrderDetail'));
const AssuranceTracker = lazy(() => import('../features/assurance/tracker/AssuranceTracker'));
const ProjectDetail = lazy(() => import('../features/projects/detail/ProjectDetail'));
const Budgets = lazy(() => import('../features/budgets/Budgets'));
const Proposals = lazy(() => import('../features/proposals/Proposals'));
const Contracts = lazy(() => import('../features/contracts/Contracts'));
//Route Type
export interface RouteType {
    id: string;
    name: string;
    path: string;
    component: ComponentType<any>;
}
//Root Suite
const rootSuite = {
    id: "root",
    name: "Root",
    path: URLS.root,
    component: Dashboard,
};
//Admin Suite
const adminSuite = {
    id: "admin",
    name: "Admin",
    routes: {
        companies: {
            id: "companies",
            name: "Companies",
            path: URLS.companies,
            component: Companies,
        },
        companyDetail: {
            id: "companyDetail",
            name: "Company Detail",
            path: URLS.companyDetail,
            component: CompanyDetail,
        },
        divisionDetail: {
            id: "divisionDetail",
            name: "Division Detail",
            path: URLS.divisionDetail,
            component: DivisionDetail,
        },
    }
};
//Supervisor Suite
const supervisorSuite = {
    id: "supervisor",
    name: "Supervisor",
    routes: {  
        startTracker: {
            id: "startTracker",
            name: "Start Tracker",
            path: URLS.starts,
            component: StartTracker,
        },
        starts: {
            id: "starts",
            name: "Starts",
            path: URLS.starts,
            component: Starts,
        },
        startDetail: {
            id: "startDetail",
            name: "Start Detail",
            path: URLS.startDetail,
            component: StartDetail,
        },
        officeReports: {
            id: "reports",
            name: "Office Reports",
            path: URLS.reports,
            component: ReportView,
        },
        fieldReports: {
            id: "fieldReports",
            name: "Field Reports",
            path: URLS.fieldReports,
            component: FieldReports,
        },
    }
};
//Estimating Suite
const estimatingSuite = {
    id: "estimating",
    name: "Estimating",
    routes: {
        projects: {
            id: "projects",
            name: "Projects",
            path: URLS.projects,
            component: Projects,    
        },
        projectDetail: {
            id: "project_detail",
            name: "Project Detail",
            path: URLS.projectDetail,
            component: ProjectDetail,    
        },
        budgets: {
            id: "budgets",
            name: "Budgets",
            path: URLS.budgets,
            component: Budgets,
        },
        budget: {
            id: "budgetItems",
            name: "Budget Items",
            path: URLS.budget,
            component: WrappedItemView,
        },
        proposals: {
            id: "proposals",
            name: "Proposals",
            path: URLS.proposals,
            component: Proposals,
        },
        proposal: {
            id: "proposalItems",
            name: "Proposal Items",
            path: URLS.proposal,
            component: WrappedItemView,
        },
        contracts: {
            id: "contracts",
            name: "Contracts",
            path: URLS.contracts,
            component: Contracts,
        },
        contract: {
            id: "contractItems",
            name: "Contract Items",
            path: URLS.contract,
            component: WrappedItemView,
        },
        starts: {
            id: "starts",
            name: "Starts",
            path: URLS.startsByProject,
            component: Starts,
        }
    }
};
//Assurance Suite
const assuranceSuite = {
    id: "assurance",
    name: "Assurance",
    routes: {
        assuranceDetail: {
            id: "assuranceDetail",
            name: "Assurance Detail",
            path: URLS.assurance,
            component: AssuranceDetail,
        },
        futureOrders: {
            id: "futureOrders",
            name: "Future Orders",
            path: URLS.futureOrders,
            component: AssuranceTracker,
        },
    }
};
//Warehouse Suite
const warehouseSuite = {
    id: "warehouse",
    name: "Warehouse",
    routes: {
        warehouseOrders: {
            id: "warehouseOrders",
            name: "Warehouse Orders",
            path: URLS.warehouseOrders,
            component: WarehouseOrders,
        },
        warehouseOrderDetail: {
            id: "warehouseOrderDetail",
            name: "Warehouse Order Detail",
            path: URLS.warehouseOrderDetail,
            component: WarehouseOrderDetail,
        },
    }
};

export const suites = {
    root: rootSuite,
    admin: adminSuite,
    supervisor: supervisorSuite,
    estimating: estimatingSuite,
    assurance: assuranceSuite,
    warehouse: warehouseSuite,
};

