import { useState, useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import { RouteType, suites } from "./routes";

const useRouteList = () => {
    const { userDetails } = useAppSelector((state: any) => state.auth);
    const [filteredRoutes, setFilteredRoutes] = useState<RouteType[]>([]);

    useEffect(() => {
        const routes: Set<RouteType> = new Set();
        const userGroups = userDetails?.groups || [];
        // Root
        routes.add(suites.root);
        
        // Admin
        if (userGroups.includes('Admin')) {
            Object.values(suites.admin.routes).forEach((route: RouteType) => routes.add(route));
        }
        // Assurance
        if (userGroups.includes('Assurance')) {
            Object.values(suites.assurance.routes).forEach((route: RouteType) => routes.add(route));
        }
        // Add default routes
        Object.values(suites.supervisor.routes).forEach((route: RouteType) => routes.add(route));
        Object.values(suites.estimating.routes).forEach((route: RouteType) => routes.add(route));

        // Warehouse
        if (userGroups.includes('Warehouse')) {
            Object.values(suites.warehouse.routes).forEach((route: RouteType) => routes.add(route));
            setFilteredRoutes(Array.from(routes));
            if(!userGroups.includes('Admin')) return;
        }
        setFilteredRoutes(Array.from(routes));
    }, [userDetails]); 

    return filteredRoutes;
}

export default useRouteList;