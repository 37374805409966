
// React app urls
export const URLS = {
    root: "/",
    login: "/login",
    logout: "/logout", 
    organizationalUnits: "/organizational-units",
    companies: "/organizational-units/companies",
    companyDetail: "/organizational-units/companies/:company_id",
    divisionDetail: "/organizational-units/companies/:company_id/divisions/:division_id",
    projects: "/projects",
    projectDetail: "/projects/:project_id",
    lotList: "/lots",
    fndSpecs: "/projects/:project_id/specifications/",
    checkList: "/projects/:project_id/project-checklist/",
    fndSpec: "/projects/:project_id/specifications/:specification",
    scheduleOfValues: "/projects/:project_id/sov",
    fndBudgets: "/fnd-budgets",
    fwSpecs: "/fw-specs",
    fwBudgets: "/fw-budgets",
    warehouseMaterialPricing: "/warehouse-materials",
    warehouseOrders: "/warehouse-orders",
    warehouseOrderDetail: "/projects/:project_id/warehouse-orders/:start_id",
    warehouseOrder: "/projects/:project_id/warehouse-orders/:order_id",
    plps: "/projects/:project_id/pre-built-orders",
    concreteMixDesignPricing: "/concrete/mix-design-pricings",
    aggregatePricing: "/concrete/aggregate-pricing",
    plp: "/projects/:project_id/pre-built-orders/:plp_id",
    starts: "/starts",
    startsByProject: "/projects/:project_id/starts",
    startDetail: "/projects/:project_id/starts/:start_id",
    reports: "/projects/:project_id/office-reports/:start_id",
    fieldReports: "/projects/:project_id/field-reports/:start_id",
    contracts: "/projects/:project_id/contracts",
    contract: "/projects/:project_id/contracts/:contract_id",
    contractProposalReview: "/projects/:project_id/contract-proposal-review",
    proposals: "/projects/:project_id/proposals/",
    proposal: "/projects/:project_id/proposals/:proposal_id",
    budgets: "/projects/:project_id/budgets/",
    budget: "/projects/:project_id/budgets/:budget_id",
    assurance: "/projects/:project_id/assurance/:start_id",
    futureOrders: "/future-orders",
};

export default URLS;





