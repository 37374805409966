import {createSlice} from '@reduxjs/toolkit';

export interface API_Data {
    isError: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    formErrors: any; 
    message: string,
    activeTabIndex:number, 
    activeProject: any,
    activeProjectItem: any,
    activeTag: string
};

const initialState: API_Data = {
    isError: false,
    isLoading: false,
    isSuccess: false,
    formErrors: {}, 
    message: "",
    activeTabIndex: 0,
    activeProject: {},
    activeProjectItem: {},
    activeTag: ""
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        resetProjectState: (_state) => initialState,
        setIndex: (state, action) => {
            state.activeTabIndex = action?.payload;
        },
        setActiveTag: (state, action) => {
            state.activeTag = action.payload;
        },
        setActiveProject: (state, action) => {
            state.activeProject = action.payload;
        },
        setActiveProjectItem: (state, action) => {
            state.activeProjectItem = action.payload;
        },
        clearFormErrors: (state) => {
            state.formErrors = {}; // Clear form errors
        },

    }
});

export const {resetProjectState, setIndex, setActiveTag, setActiveProject, setActiveProjectItem, clearFormErrors} = projectSlice.actions;
export default projectSlice.reducer;